import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Map() {
  const videos = [
    {
      text:
        "Please watch this 1 minute video sharing the reason CA Cities for Local Control was formed",
      youtubeVideoId: "_SDclEZyIaU",
    },
    {
      text:
        "Listen to the City of Covina discuss their support for CA Cities for Local Control",
      youtubeVideoId: "RUYJFmxRJBc",
    },
    {
      text:
        "Listen to the City of Bellflower discuss their support for CA Cities for Local Control",
      youtubeVideoId: "2ft0Z8yYyZ0",
    },
    {
      text:
        "Listen to the City of Anderson discuss their support for CA Cities for Local Control",
      youtubeVideoId: "BoF7bRmuTrk",
    },
  ]

  return (
    <Layout>
      <SEO title="Home" />
      <h1>Home</h1>
      <blockquote className="px-5">
        <h2>Important News</h2>
        <p>
          Sacramento Legislators continue their attack on Cities ability to
          manage their own land-use and zoning. And CCLC is continuing our
          efforts to bring attention to the bills that impact cities in this
          way, and to lobby legislators to stop the passage of these types of
          bills, and instead support bills that encourage desired behaviors,
          while respecting the individuality of Cities.
        </p>

        <a
          href="https://ourneighborhoodvoices.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/images/our-neighborhood-voices.png"
            alt="Our Neighborhood Voices"
            width="300"
            className="float-left mr-5"
          />
        </a>

        <p>
          While SB9 and SB10 did pass in the the 2021 legislative session, CCLC
          still supports efforts to minimize or even stop the impacts of these
          bills. One way is the continued support of the ballot initiative by
          ‘Our Neighborhood Voices’, which is now being setup to try to get on
          the November 2024 ballot. And another way we are helping to fight back
          is via legal efforts, of which several options are in progress.
        </p>

        <p>
          CCLC will continue to highlight good and bad bills impacting local
          land-use and zoning, as well as keep everyone up to date on the
          efforts to get a ballot initiative on the ballots to allow the voters
          a say. And lastly, we will share ways Cities can get involved in the
          legal battles, which stand the best chances of success with the
          support of many Cities. We hope you will continue to support our
          efforts!
        </p>
        <p className="mb-0">
          Join in our efforts now! Together, we can stop Sacramento from
          "unplanning" our communities.
        </p>
      </blockquote>

      <p>
        California Cities for Local Control, a grassroots organization founded
        in July 2020 by Torrance City Council Member Mike Griffiths, is an
        advocacy group for local control over zoning and land use decisions.
        Councilmember Griffiths has assembled a volunteer organization of
        several dozen people from all around California, including other elected
        officials, homeowner group representatives, city planners, and
        passionate homeowners, all sharing the common belief that Local
        Governments should remain the ultimate decision makers for how their
        communities are built.
      </p>
      <p>
        Our volunteers are tasked to reach out to every elected Mayor and
        Councilmember for every city in California, and to identify those
        elected officials who feel the same way about how our cities are
        managed. We believe that joining like-minded elected officials together
        around a common mission will send a strong, united statement to those
        involved in the state bill-making process. This is a massive undertaking
        that no other organization has attempted. But we believe that the goal
        is worth this effort.
      </p>
      <h2>Mission</h2>
      <p>
        Our mission is to spread awareness and enlist support to ensure that
        Cities can continue to manage their own land use and zoning issues. We
        must not allow the State Legislature to mandate changes to our Cities
        that will remove local control and be detrimental to our communities.
      </p>
      <p>
        We do this by reaching out to California City Elected Officials to
        educate and enlist them to our cause. Our activities include, but are
        not limited to, signing onto petitions, having Cities pass resolutions
        in support of our efforts, and seeking out allies for possible legal
        action against the State and/or to promote efforts for a ballot
        initiative to legislate the desired results.
      </p>
      <p>
        With this alliance of City Elected Officials working together as one, we
        stand a better chance of having our message resonate loud and clear to
        all groups that proclaim to support us.
      </p>
      <blockquote className="px-5 text-lg lg:text-3xl lg:px-8">
        WE WILL NOT STAND FOR THIS LOSS OF LOCAL CONTROL AND UNFUNDED MANDATES
        being imposed upon us by our State.
      </blockquote>

      {videos.map(({ text, youtubeVideoId }) => {
        return (
          <div key={youtubeVideoId}>
            <a
              href={`https://www.youtube.com/watch?v=${youtubeVideoId}&feature=youtu.be`}
              target="_blank"
              rel="noreferrer"
              className="block mt-3 lg:hidden"
            >
              {text}
            </a>
            <div className="flex-col hidden lg:flex-row lg:flex">
              <p className="w-full p-0 text-2xl leading-relaxed lg:w-1/3 lg:pr-5">
                {text}
              </p>
              <iframe
                width="560"
                height="315"
                className="mt-5"
                src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title={text}
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )
      })}

      <p className="mt-5 lg:mt-12">
        For more videos, please visit&nbsp;
        <a
          href="https://www.youtube.com/channel/UC6sx0NOQOTez_uwFK6dClgQ/featured"
          target="_blank"
          rel="noreferrer"
        >
          our YouTube channel.
        </a>
      </p>
    </Layout>
  )
}
